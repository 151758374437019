.contact {
	padding-top: 120px;
	padding-bottom: 200px;

	.contact_red_bubble {
		position: relative;
		width: 38px;
		height: 38px;
		padding: 11px 8px;
		border-radius: 7px;
		background: #bd0000;

		svg {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
		}
	}

	.contact_main {
		display: flex;
		justify-content: space-between;
		flex-direction: column-reverse;
		gap: 40px;

		.contact_left {
			display: flex;
			align-items: flex-start;
			justify-content: center;
			gap: 150px;
		}

		.contact_left_top {
			margin-bottom: 40px;
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			gap: 40px;

			.contact_left_wrap {
				display: flex;
				align-items: center;
				gap: 20px;

				h3 {
					color: #212121;
					font-size: 18px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					margin-bottom: 5px;
				}

				.contact_left_title {
					color: #212121;
					font-size: 24px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				}
			}
		}

		.contact_socials {
			display: inline-flex;
			align-items: flex-start;
			gap: 20px;
		}

		.contact_right {
			width: 100%;
			height: 600px;

			iframe {
				height: 100%;
			}
		}
	}
}

@media (max-width: 500px) {
	.contact {
		margin-top: 0;
		padding-bottom: 90px;

		.contact_red_bubble {
			width: 30px;
			height: 30px;
			padding: 5px;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				position: relative;
				margin: 0;
				width: 20px;
				height: 20px;
			}
		}

		.contact_main {
			.contact_left {
				display: block;
				margin: 0 auto;
			}
			.contact_left_top {
				margin-bottom: 20px;
				gap: 20px;

				.contact_left_wrap {
					gap: 15px;

					h3 {
						font-size: 14px;
					}

					.contact_left_title {
						font-size: 16px;
					}
				}
			}

			.contact_socials {
				gap: 15px;
			}

			.contact_right {
				width: 100%;
				height: 222px;
			}
		}
	}
}
