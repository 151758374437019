.categoriesPage {
	padding-top: 120px;
	padding-bottom: 100px;

	.title {
		color: #101828;
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: 40px;
		letter-spacing: -0.64px;
		margin-bottom: 25px;
	}

	.categoriesPage_wrap {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 30px;
		margin-bottom: 60px;
	}
}

@media (max-width: 500px) {
	.categoriesPage {
		padding-bottom: 40px;

		.title {
			line-height: 38px;
			margin-bottom: 16px;
		}

		.categoriesPage_wrap {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
		}
	}
}
