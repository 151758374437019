.basket {
	padding-top: 120px;
	padding-bottom: 80px;
	.basket_main {
		display: flex;
		justify-content: space-between;
		gap: 20px;
		align-items: flex-start;

		.basket_wrap {
			width: 100%;
			border: 1px solid #edf0f2;
			border-radius: 8px;
			.basket_card {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 15px 20px;
				border-bottom: 1px solid #edf0f2;

				.basket_card_left {
					display: flex;
					align-items: center;
					width: 45%;

					.basket_img {
						position: relative;
						width: 90px;
						height: 90px;

						img {
							position: absolute;
							width: 100%;
							height: 100%;
							object-fit: contain;
							inset: 0px;
						}
					}

					.basket_caption {
						color: #2c333d;
						font-size: 16px;
						font-style: normal;
						font-weight: 550;
						line-height: 26px;
					}
				}

				.basket_price {
					color: #003b73;
					font-size: 20px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px;
					width: 20%;
				}

				.basket_card_right {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 50px;

					.basket_wrap_count {
						display: flex;
						align-items: center;
						gap: 20px;

						button {
							position: relative;
							width: 61px;
							height: 43px;
							border-radius: 900px;
							background: #f3f4f5;
							color: #4a5056;
							font-size: 20px;
							padding: 10px 0;
							outline: none;
							border: none;
							cursor: pointer;

							svg {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
							}
						}

						span {
							color: #2c333d;
							text-align: center;
							font-size: 17px;
							font-style: normal;
							font-weight: 400;
							line-height: 11px;
						}
					}

					.basket_mobile_price {
						display: none;
					}

					.basket_delete {
						border: none;
						outline: none;
						background: transparent;
						margin-left: auto;
						path {
							color: #adadad;
							transition: all 0.3s ease-in;

							&:hover {
								cursor: pointer;
								color: #6b0000;
							}
						}
					}
				}
			}
		}

		.basket_total {
			position: sticky;
			right: 0;
			max-width: 350px;
			width: 100%;
			padding: 25px;
			border-radius: 6px;
			background: #fff;
			box-shadow: 0px 4px 19px 0px rgba(23, 27, 32, 0.07);

			h2 {
				color: #1d1d27;
				font-size: 28px;
				font-style: normal;
				font-weight: 550;
				line-height: 44px;
			}
			margin-bottom: 25px;

			.basket_total_wrap {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 20px;
				.basket_total_value {
					color: #000;
					font-size: 15px;
					font-style: normal;
					font-weight: 550;
					line-height: 28px;
				}
				.basket_total_key {
					color: #003b73;
					font-size: 22px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px;
				}
			}

			.basket_buy {
				width: 100%;
				height: 50px;
				border-radius: 8px;
				background: #101828;
				border: 1px solid #101828;
				color: #fff;
				text-align: center;
				font-size: 13px;
				font-style: normal;
				font-weight: 700;
				line-height: 17px;
				text-transform: uppercase;
				transition: all 0.3s ease-in;

				&:hover {
					cursor: pointer;
					background: #fff;
					color: #101828;
				}
			}
		}
	}

	.no_data {
		svg {
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 20px;
		}

		h2 {
			color: #2c333d;
			text-align: center;
			font-size: 20px;
			font-style: normal;
			font-weight: 550;
			line-height: 26px;
			margin-bottom: 20px;
		}

		p {
			color: #383838;
			text-align: center;
			font-size: 15px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
			margin-bottom: 20px;
		}

		a {
			display: block;
			margin: 0 auto;
			max-width: 200px;
			width: 100%;
			height: 60px;
			border-radius: 8px;
			border: 1px solid #101828;
			background: #101828;
			color: #fff;
			text-align: center;
			font-size: 13px;
			font-style: normal;
			font-weight: 700;
			line-height: 60px;
			text-transform: uppercase;
			transition: all 0.3s ease-in;

			&:hover {
				cursor: pointer;
				background-color: #fff;
				color: #101828;
			}
		}
	}
}

@media (max-width: 500px) {
	.basket {
		.basket_main {
			display: block;

			.basket_wrap {
				border: none;
				.basket_card {
					display: block;
					padding: 15px 0;
					border-bottom: 1px solid #edf0f2;

					.basket_card_left {
						display: flex;
						align-items: center;
						gap: 10px;
						width: 100%;
						margin-bottom: 10px;
					}

					.basket_price {
						display: none;
					}

					.basket_card_right {
						display: flex;
						align-items: center;
						justify-content: space-between;

						.basket_wrap_count {
							display: flex;
							align-items: center;
							gap: 10px;
						}

						.basket_mobile_price {
							display: block;
							color: #003b73;
							font-size: 14px;
							text-wrap: nowrap;
							display: block ruby;
							font-style: normal;
							font-weight: 400;
							line-height: 22px;
						}
					}
				}
			}

			.basket_total {
				position: relative;
				max-width: 100%;
				width: 100%;

				h2 {
					font-size: 24px;
					line-height: 21px;
					margin-bottom: 15px;
				}
				margin-bottom: 25px;

				.basket_buy {
					height: 45px;
				}
			}
		}
		.no_data {
			padding-top: 60px;

			a {
				height: 45px;
				line-height: 45px;
			}
		}
	}
}
