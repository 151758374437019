.favorites {
	padding-top: 120px;
	padding-bottom: 100px;

	.no_data {
		svg {
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 20px;
		}

		h2 {
			color: #2c333d;
			text-align: center;
			font-size: 20px;
			font-style: normal;
			font-weight: 550;
			line-height: 26px;
			margin-bottom: 20px;
		}

		p {
			color: #383838;
			text-align: center;
			font-size: 15px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
			margin-bottom: 20px;
		}

		a {
			display: block;
			max-width: 200px;
			margin: 0 auto;
			border-radius: 8px;
			background: #101828;
			height: 67px;
			color: #fff;
			text-align: center;
			font-size: 13px;
			font-style: normal;
			font-weight: 700;
			line-height: 67px;
			text-transform: uppercase;
		}
	}

	.favorite_products {
		display: grid;
		grid-template-columns: repeat(8, 1fr);
		place-items: center;
		gap: 30px;
	}
}

@media (max-width: 500px) {
	.favorites {
		.no_data {
			padding-top: 60px;
			a {
				height: 45px;
				line-height: 45px;
			}
		}

		.favorite_products {
			grid-template-columns: repeat(2, 1fr);
			place-items: center;
			gap: 10px;
		}
	}
}
