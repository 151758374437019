.not_found {
	padding: 150px 0;

	svg {
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
	}

	p {
		color: #535557;
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 29px;
		margin-bottom: 20px;
	}

	a {
		display: block;
		margin-left: auto;
		margin-right: auto;
		max-width: 210px;
		width: 100%;
		height: 64px;
		border-radius: 8px;
		background: #021e34;
		border: 1px solid #021e34;
		color: #fff;
		text-align: center;
		font-size: 13px;
		font-style: normal;
		font-weight: 700;
		line-height: 64px;
		text-transform: uppercase;
		transition: all 0.3s ease-in;

		&:hover {
			color: #021e34;
			background-color: #fff;
		}
	}
}

@media (max-width: 500px) {
	.not_found {
		h2 {
			font-size: 30px;
			line-height: 33px;
			margin-bottom: 40px;
		}

		svg {
			width: 100%;
		}

		p {
			color: #535557;
			text-align: center;
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: 29px;
			margin-bottom: 55px;
		}

		a {
			display: block;
			margin-left: auto;
			margin-right: auto;
			max-width: 210px;
			width: 100%;
			height: 45px;
			border-radius: 8px;
			background: #021e34;
			border: 1px solid #021e34;
			color: #fff;
			text-align: center;
			font-size: 13px;
			font-style: normal;
			font-weight: 700;
			line-height: 45px;
		}
	}
}
