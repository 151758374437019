.product {
	padding-top: 120px;
	padding-bottom: 100px;

	.main {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		gap: 50px;

		.catalog {
			position: sticky;
			top: 50px;
			width: 35%;
			padding: 20px;

			h3 {
				font-weight: 600;
				font-size: clamp(18px, 3.5vmin, 22px);
				color: rgba(45, 48, 52, 1);
				letter-spacing: -0.7px;
				line-height: 1.3;
				white-space: normal;
				overflow: initial;
				text-overflow: initial;
				-webkit-line-clamp: initial;
				-webkit-line-clamp: 2;
				margin-bottom: 20px;
			}

			.catalog_body {
				box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.04);

				padding: 10px;
				border-radius: 8px;
				overflow: hidden;
				max-width: 260px;
				width: 100%;
			}

			.catalog_item {
				display: flex;
				align-items: center;
				gap: 5px;
				padding: 5px 0;
				text-decoration: none;
				transition: all 0.3s ease-in;

				.ant-skeleton {
					.ant-skeleton-content {
						.ant-skeleton-title {
							width: 100% !important;
							margin-bottom: 0;
						}
					}
				}

				.catalog_img_wrap {
					display: block;
					width: 36px;
					height: 36px;
					padding: 2px;
					margin-right: 8px;
					border-radius: 8px;
					border: 1px solid rgba(215, 217, 224, 1);

					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}

				.catalog_info {
					font-weight: 600;
					font-size: 16px;
					color: #2d3034ab;
					max-width: 100%;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					line-height: 1.1;
					transition: all 0.3s ease-in;

					&:hover {
						color: #2d3034;
					}
				}
			}
		}
	}

	.product_main {
		.product_mobile_caption {
			display: none;
		}
		.product_wrapper {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			gap: 32px;
			margin-bottom: 40px;

			.main_right {
				width: 50%;
			}

			.product_left {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				width: 50%;
				gap: 14px;

				.mySwiper {
					width: 80px;
					height: 480px;

					.swiper {
						display: block;
						margin: 0;
					}
					.ant-skeleton {
						margin-bottom: 10px;
					}
					.ant-skeleton-image {
						width: 80px;
						height: 75px;
					}

					.swiper-wrapper {
						display: block;
						.swiper-slide {
							width: 100% !important;
							height: 75px;
							overflow: hidden;
							margin: 0 0 10px 0 !important;
							transition: all 0.3s ease-in;
							border-radius: 5px;
							border: 1px solid #838587;

							img {
								width: 100%;
								height: 100%;
								object-fit: contain;
							}

							&:hover {
								cursor: pointer;
								border-radius: 5px;
								border-color: rgba(246, 38, 38, 1);
							}
						}

						.swiper-slide-thumb-active {
							border-radius: 5px;
							border: 1px solid rgba(246, 38, 38, 1);
						}
					}
				}

				.mySwiper2 {
					width: 480px;
					height: 480px;
					border: 1px solid rgba(218, 221, 224, 1);
					border-radius: 8px;

					.swiper-slide {
						display: flex;
						flex-direction: column-reverse;
						border-radius: 5px;
						// border: 1px solid #838587;
						margin: 0 !important;
						width: 480px;
						height: 417px;
						overflow: hidden;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							vertical-align: middle;
						}
					}

					.ant-skeleton,
					.ant-skeleton-image {
						width: 100%;
						height: 100%;

						svg {
							width: 20%;
							height: 20%;
						}
					}

					.swiper-button-prev,
					.swiper-button-next,
					.swiper-button-lock {
						display: none;
						width: 50px;
						height: 50px;
						background: #021e34;
						border-radius: 20px;
						opacity: 0.8;
					}

					.swiper-button-lock::after {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						font-size: 20px;
						color: #fff;
					}

					.swiper-button-prev,
					.swiper-button-next {
						&::after {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							font-size: 20px;
							color: #fff;
						}
					}

					transition: all 0.3s ease-in;

					&:hover {
						.swiper-button-prev,
						.swiper-button-next {
							display: block !important;
						}
						cursor: pointer;
					}
				}
			}

			.product_right {
				width: 42%;
				.ant-skeleton {
					.ant-skeleton-content {
						.ant-skeleton-title {
							width: 100% !important;
						}
					}
				}

				.product_right_title {
					color: #070c11;
					font-size: 36px;
					font-style: normal;
					font-weight: 700;
					line-height: 130%;
					margin-bottom: 30px;
				}

				.product_right_info {
					display: block;
					color: #2d303499;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 120%;
					margin-bottom: 40px;
				}

				.product_right_box {
					display: flex;
					align-items: flex-start;
					gap: 20px;
					padding: 20px;
					border-radius: 16px;
					box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.04);
					margin-bottom: 30px;

					.product_right_price {
						font-size: 26px;
						color: #2d3034;
						font-weight: 600;
						text-wrap: nowrap;
						letter-spacing: -1px;
						line-height: 1;
					}

					.brend {
						display: flex;
						align-items: flex-start;
						flex-direction: column;
						gap: 10px;
						img {
							width: 70px;
							height: 70px;
							object-fit: contain;
							border-radius: 8px;
						}
					}

					.product_right_box_texts {
						padding: 20px;
						width: 100%;
						span {
							display: flex;
							align-items: flex-start;
							gap: 6px;
							color: #2d303499;
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							line-height: 26px;
							margin-bottom: 6px;
							text-wrap: nowrap;
						}

						.stats {
							color: rgba(9, 195, 63, 1);
							
						}
					
						.un_stats {
							color: #ff435e;
						}

						.model {
							text-decoration: none;
							color: #2d3034;
						}
					}
				}

				.product_right_bottom_box {
					padding: 24px;
					border-radius: 16px;
					box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.04);

					.buttons_wrap {
						display: flex;
						align-items: center;
						gap: 5px;
						margin-bottom: 10px;

						.count_wrap {
							display: flex;
							align-items: center;
							border: 1px solid rgba(215, 217, 224, 1);
							border-radius: 8px;
							max-width: 108px;
							width: 100%;
							height: 44px;
							overflow: hidden;

							button {
								position: relative;
								width: 36.5px;
								height: 100%;
								min-height: 36px;
								background-color: rgba(232, 237, 242, 1);
								cursor: pointer;
								outline: none;
								border: none;

								svg {
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
								}
							}

							span {
								color: #2c333d;
								text-align: center;
								font-size: 17px;
								font-style: normal;
								font-weight: 400;
								line-height: 11px;
								width: 36px;
							}
						}

						.buy {
							max-width: 140px;
							width: 100%;
							height: 44px;
							background-color: #6b0000;
							border-radius: 8px;
							color: #fff;
							font-size: 14px;
							font-weight: 400;
							line-height: 24px;
							text-align: center;
							border: 1px solid #6b0000;
							outline: none;
							transition: all 0.3s ease-in;

							&:hover {
								cursor: pointer;
								color: #6b0000;
								background: #fff;
							}
						}

						.share {
							display: flex;
							align-items: center;
							justify-content: center;
							gap: 5px;
							border: 1px solid #021e34;
							background-color: #021e34;
							transition: all 0.3s ease-in;
							color: #fff;
							font-size: 14px;
							font-weight: 400;
							height: 44px;
							line-height: 24px;
							max-width: 140px;
							width: 100%;
							border-radius: 8px;
							padding: 0 0.8rem;

							svg {
								width: 20px;
								height: 20px;
								path {
									stroke: #fff;
								}
								fill: #fff;
							}

							&:hover {
								background-color: #fff;
								color: #021e34;
								cursor: pointer;
								svg {
									path {
										stroke: #021e34;
									}
									fill: #021e34;
								}
							}
						}
					}

					.like {
						display: flex;
						align-items: center;
						gap: 5px;
						transition: all 0.3 ease-in;
						max-width: 100px;
						width: 100%;

						span {
							font-size: 14px;
							color: rgba(45, 48, 52, 1);
							text-transform: none;
							text-decoration-thickness: 1px;
							-webkit-text-decoration-thickness: 1px;
							text-underline-offset: 5px;
							letter-spacing: 0;
						}

						svg {
							width: 20px;
							height: 20px;

							path {
								stroke: rgba(45, 48, 52, 1);
							}
						}

						&:hover {
							cursor: pointer;

							span {
								color: #6b0000;
							}

							svg {
								path {
									stroke: #6b0000;
									fill: #6b0000;
								}
							}
						}
					}

					.active_like {
						display: flex;
						align-items: center;
						gap: 5px;
						transition: all 0.3 ease-in;
						max-width: 100px;
						width: 100%;
						cursor: pointer;

						span {
							font-size: 14px;
							color: #6b0000;
							text-transform: none;
							text-decoration-thickness: 1px;
							-webkit-text-decoration-thickness: 1px;
							text-underline-offset: 5px;
							letter-spacing: 0;
						}

						svg {
							width: 20px;
							height: 20px;

							path {
								fill: #6b0000;
								stroke: #6b0000;
							}
						}
					}
				}
			}
		}

		.product_bar {
			border-radius: 24px;
			box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.04);

			.product_text_header {
				display: flex;
				gap: 10px;
				align-items: center;
				padding: 0 20px;
				margin-bottom: 32px;

				span {
					cursor: pointer;
					display: block;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 45px;

					height: 45px;
					padding: 0 0.8em;
					border-radius: 16px;
					background: #f0f0f1;
					border: 1px solid #f0f0f1;
					color: #2d3034;
					transition: all 0.3s ease-in;

					&:hover {
						background: #021e34;
						border: 1px solid #021e34;
						color: #fff;
					}
				}

				.active {
					position: relative;
					background: #021e34;
					border: 1px solid #021e34;
					color: #fff;
				}
			}

			.product_text_bottom {
				padding: 35px;

				span {
					color: #2d303499;
					font-size: 15px;
					font-style: normal;
					font-weight: 400;
					line-height: 120%;
				}

				.on_active {
					display: none;
				}
			}
		}
	}
}

@media (max-width: 500px) {
	.product {
		padding-bottom: 50px;

		.main {
			display: block;
		}

		.catalog {
			display: none;
		}

		.product_main {
			.product_mobile_caption {
				display: block;
				color: #070c11;
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 130%;
				margin-bottom: 10px;
			}

			.product_wrapper {
				flex-direction: column;
				justify-content: center;
				gap: 30px;

				.product_left {
					width: 100%;
					.mySwiper {
						width: 80px;
						height: 280px;

						.swiper {
							display: block;
							margin: 0;
						}
						.ant-skeleton {
							margin-bottom: 10px;
						}
						.ant-skeleton-image {
							width: 46px;
							height: 46px;

							svg {
								width: 40%;
							}
						}

						.swiper-wrapper {
							.swiper-slide {
								width: 100% !important;
								height: 48px;
							}
						}
					}

					.mySwiper2 {
						height: 280px;

						.swiper-slide {
							height: 280px;
						}
					}
				}

				.product_right {
					width: 100%;

					.product_right_title {
						display: none;
					}

					.product_right_info {
						margin-bottom: 20px;
					}

					.product_right_box {
						flex-direction: column;
						gap: 20px;
						margin-bottom: 0;

						.product_right_price {
							font-size: 24px;
						}
					}

					.product_right_bottom_box {
						position: fixed;
						bottom: 0;
						left: 0;
						margin: 0;
						width: 100%;
						z-index: 999;
						padding: 12px;
						background: #fff;
						box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.04);
						border: 1px solid #aeaeae;
						border-radius: 0;
						border-top-left-radius: 8px;
						border-top-right-radius: 8px;

						.buttons_wrap {
							.buy {
								max-width: 194px;
							}

							.share {
								justify-content: center;
								gap: 0;
								max-width: 40px;
								height: 40px;
								font-size: 0;
								padding: 0;
							}
						}

						.like,
						.active_like {
							justify-content: center;
							max-width: 100%;
						}
					}
				}
			}

			.product_text_bottom {
				margin-bottom: 40px;
			}
		}
	}

	.product + footer .footer_bottom {
		padding-bottom: 260px !important;
	}
}
