.ant-modal-content {
	.ant-modal-title {
		color: #2c333d;
		font-size: 25px;
		font-style: normal;
		font-weight: 600;
		line-height: 33px;
	}

	.ant-form {
		.ant-form-item {
			.ant-form-item-label {
				text-align: start;
			}
		}

		.ant-col {
			flex: 0 0 100%;
			.ant-form-item-control-input {
				margin-bottom: 5px;
			}

			.ant-form-item-required {
				color: #011120;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 11px;
			}

			.ant-input {
				width: 100%;
				height: 50px;
				padding: 20px;
				border-radius: 7px;
				border: 1px solid #ebeef0;
				background: #fff;
				box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07) inset;

				color: #adb1b7 !important;
				font-size: 15px;
				font-style: normal;
				font-weight: 400;
				line-height: 11px;
			}

			.ant-form-item-explain-error {
				color: #e52b0e;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 11px;
			}

			.form-control {
				width: 100%;
				height: 50px;
				padding: 20px;
				border-radius: 7px;
				border: 1px solid #ebeef0;
				background: #fff;
				box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07) inset;

				color: #adb1b7;
				font-size: 15px;
				font-style: normal;
				font-weight: 400;
				line-height: 11px;
			}
		}
	}

	.ant-modal-footer {
		text-align: center;

		.ant-btn {
			height: 40px;
			border-radius: 8px;
			max-width: 150px;
			width: 100%;
		}

		.ant-btn:first-child {
			border: 1px solid #e52b0e;
			color: #e52b0e;
		}

		.ant-btn:last-child {
			background: #021e34;

			span {
				color: #fff;
				text-align: center;
				font-size: 13px;
				font-style: normal;
				font-weight: 700;
				line-height: 17px;
				text-transform: uppercase;
			}
		}
	}
}
