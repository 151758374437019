.home_headline {
	color: #101828;
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 40px;
	letter-spacing: -0.64px;
	margin-bottom: 25px;
}

.slider {
	margin-top: 90px;
	margin-bottom: 20px;
	.slick-slider {
		z-index: 1;
	}
	.slick-active {
		z-index: 2;
	}

	.slider_top {
		a {
			img {
				width: 100%;
				object-fit: cover;
				height: 480px;
			}
		}
	}

	.skeleton_banner {
		width: 100% !important;
		height: 480px !important;
		margin-bottom: 20px;
	}

	.slick-arrow {
		width: 40px;
		height: 40px;
		border: 1px solid #fff;
		background: #ffffffe6;
		border-radius: 8px;
		top: 50%;
		transform: translate(0, -50%);
		z-index: 9;
		backdrop-filter: blur(8px);

		&::before {
			content: '';
			position: absolute;
			width: 10px;
			height: 19px;
			background-image: url('../../assets/svg/Vector.svg');
			background-repeat: no-repeat;
			background-size: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
		}
	}

	.slick-disabled {
		opacity: 0.5;

		&::before {
			opacity: 1;
		}
	}

	.slick-prev {
		left: 40px;
	}

	.slick-next {
		right: 40px;

		&::before {
			transform: rotate(180deg);
		}
	}
}

.intro {
	background: #f6f7fa;
	padding: 90px 0;

	.intro_wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 80px;
		margin-bottom: 60px;

		.intro_left {
			img {
				object-fit: contain;
				width: 100%;
				height: 100%;
			}
		}

		.intro_right {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 40px;

			h4 {
				color: #bd0000;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: 24px;
			}

			h2 {
				color: #101828;
				font-size: 38px;
				font-style: normal;
				font-weight: 700;
				line-height: 50px;
				letter-spacing: -0.76px;
			}
		}

		.intro_left_wrap {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 40px;
			grid-template-rows: 2fr;

			span {
				display: flex;
				width: 264px;
				flex-direction: column;
				align-items: flex-start;
				gap: 20px;

				h3 {
					color: #bd0000;
					font-size: 60px;
					font-style: normal;
					font-weight: 600;
					line-height: 72px;
					letter-spacing: -1.2px;

					display: flex;
					align-items: flex-start;
					max-width: 50px;
				}

				p {
					color: #101828;
					font-size: 18px;
					font-style: normal;
					font-weight: 500;
					line-height: 28px;
				}
			}
		}
	}

	.intro_wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 25px;
	}

	.intro_wrap_card {
		display: flex;
		max-width: 384px;
		width: 100%;
		padding: 20px 0px 30px 0px;
		flex-direction: column;
		align-items: center;
		gap: 20px;

		border-radius: 10px;
		background: #fff;
		transition: all 0.3s ease-in;

		&:hover {
			cursor: pointer;
			box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

			h3 {
				color: #bd0000;
			}

			p {
				color: #021e34;
			}
		}

		h3 {
			color: #101828;
			text-align: center;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 30px;
		}

		p {
			color: #667085;
			text-align: center;

			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
		}
	}

	.intro_wrap_card_bubble {
		display: flex;
		width: 48px;
		height: 48px;
		padding: 12px;
		align-items: center;
		justify-content: center;

		border-radius: 28px;
		border: 8px solid #f6f3fb;
		background: #f1eef3;
	}
}

.products {
	padding-top: 20px;
	.swiper {
		width: 100%;
		height: 100%;
		overflow: unset;
		overflow-x: clip;
	}

	.swiper-slide {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.swiper-button-prev,
	.swiper-button-next {
		top: -50px;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background: #f3f3f3;

		&::after {
			content: '';
			background-image: url('../../assets/svg/arrow.svg');
			width: 28px;
			height: 28px;
			margin: 0 auto;
		}
	}

	.swiper-button-prev {
		left: calc(100% - 122px);
		transform: rotate(180deg);
	}
}

.news {
	padding: 50px 0;

	.news_head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 25px;

		.home_headline {
			margin-bottom: 0;
		}

		a {
			color: #021e34;
			font-size: 14px;
			font-style: normal;
			font-weight: 550;
			line-height: 11px;
			transition: all 0.3s ease-in;
			border-radius: 6px;
			background: #f6f8fb;
			height: 45px;
			width: 160px;
			line-height: 45px;
			text-align: center;

			&:hover {
				cursor: pointer;
				color: #6b0000;
			}
		}
	}

	.news_video_wrap {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 20px;
		align-items: flex-start;

		.ant-skeleton-image {
			height: 209px;
			width: 380px;
			border-radius: 8px;
			margin-bottom: 17px;
		}

		.ant-skeleton {
			.ant-skeleton-content {
				.ant-skeleton-title {
					width: 70%;
					margin-bottom: 12px;
				}
				.ant-skeleton-paragraph {
					margin-block-start: 0;
					li:first-child {
						margin-bottom: 12px;
					}
					li:last-child {
						margin-block-start: 0;
					}
				}
			}
		}

		.news_video {
			iframe {
				height: 209px;
				width: 380px;
				border-radius: 8px;
				margin-bottom: 17px;
			}

			h3 {
				color: #2c333d;
				font-size: 20px;
				font-style: normal;
				font-weight: 550;
				line-height: 26px;
				margin-bottom: 12px;
				height: 47px;
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				width: 100%;
			}

			p {
				color: #393939;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 23px;
				margin-bottom: 12px;
			}

			span {
				color: #000;
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: 12px;
			}

			transition: all 0.3s ease-in-out;

			&:hover {
				cursor: pointer;

				h3 {
					color: #6b0000;
				}

				p {
					color: #021e34;
				}

				span {
					color: #021e34;
				}
			}
		}
	}
}

@media (max-width: 500px) {
	.home_headline {
		font-size: 18px;
	}

	.slider {
		margin-top: 120px;
		margin-bottom: 16px;

		.slick-arrow {
			display: none !important;
		}

		.slick-slider:first-child {
			.slick-list {
				.slick-track {
					.slider_top {
						img {
							height: 175px;
						}
					}
				}
			}
		}

		.skeleton_banner {
			height: 175px !important;
			margin-bottom: 0;
		}
	}

	.intro {
		background: #f6f7fa;
		padding: 20px 0;

		.intro_wrapper {
			align-items: flex-start;
			flex-direction: column;
			gap: 16px;
			margin-bottom: 32px;

			.intro_left {
				width: 100%;
			}

			.intro_right {
				gap: 16px;
				h2 {
					font-size: 32px;
					line-height: 38px;
					letter-spacing: -0.64px;
					max-width: 340px;
				}
			}

			.intro_left_wrap {
				span {
					width: 100%;
					gap: 12px;

					h3 {
						max-width: 100%;
					}
				}
			}
		}

		.intro_wrap {
			flex-direction: column;
			gap: 16px;
		}

		.intro_wrap_card {
			max-width: 100%;
		}
	}

	.products {
		padding: 0;

		.swiper-button-prev,
		.swiper-button-next {
			display: none;
		}
	}

	.news {
		padding: 25px 0;
		.news_head {
			margin-bottom: 25px;
		}

		.news_video_wrap {
			grid-template-columns: 1fr;

			.news_video {
				width: 100%;

				iframe {
					width: 100%;
					height: 190px;
					margin-bottom: 10px;
				}

				h3 {
					font-size: 18px;
					line-height: 23px;
					margin-bottom: 10px;
					height: auto;
				}

				p {
					font-size: 15px;
					margin-bottom: 10px;
				}
			}

			.news_wrap_skeleton {
				width: 100%;

				.ant-skeleton-image {
					width: 100%;
					height: 190px;
					margin-bottom: 10px;
				}

				.ant-skeleton {
					width: 100%;
					.ant-skeleton-content {
						.ant-skeleton-title {
							margin-bottom: 10px;
						}
						.ant-skeleton-paragraph {
							li:first-child {
								margin-bottom: 10px;
							}
						}
					}
				}
			}
		}
	}
}
