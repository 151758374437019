.newsPage {
	padding-top: 120px;
	padding-bottom: 100px;

	.title {
		color: #101828;
		font-size: 48px;
		font-style: normal;
		font-weight: 700;
		line-height: 50px;
		letter-spacing: -0.96px;
		margin-bottom: 32px;
	}

	.newsPage_wrap {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 20px;
		align-items: flex-start;
		margin-bottom: 60px;

		.news_video {
			iframe {
				height: 209px;
				width: 380px;
				border-radius: 8px;
				margin-bottom: 17px;
			}

			h3 {
				color: #2c333d;
				font-size: 20px;
				font-style: normal;
				font-weight: 550;
				line-height: 26px;
				margin-bottom: 12px;
				width: 100%;
				height: 47px;
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
			}

			p {
				color: #393939;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 23px;
				margin-bottom: 12px;
			}

			span {
				color: #000;
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: 12px;
			}

			transition: all 0.3s ease-in-out;

			&:hover {
				cursor: pointer;

				h3 {
					color: #6b0000;
				}

				p {
					color: #021e34;
				}

				span {
					color: #021e34;
				}
			}
		}

		.ant-skeleton-image {
			height: 209px;
			width: 380px;
			border-radius: 8px;
			margin-bottom: 17px;
		}

		.ant-skeleton {
			.ant-skeleton-content {
				.ant-skeleton-title {
					width: 70%;
					margin-bottom: 12px;
				}
				.ant-skeleton-paragraph {
					margin-block-start: 0;
					li:first-child {
						margin-bottom: 12px;
					}
					li:last-child {
						margin-block-start: 0;
					}
				}
			}
		}
	}
}

@media (max-width: 500px) {
	.newsPage {
		.title {
			font-size: 32px;
			line-height: 30px;
		}

		.newsPage_wrap {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
			place-items: center;
			gap: 30px;

			.news_video {
				width: 100%;

				iframe {
					width: 100%;
					height: 190px;
					margin-bottom: 10px;
				}

				h3 {
					font-size: 18px;
					line-height: 23px;
					margin-bottom: 10px;
					height: auto;
				}

				p {
					font-size: 15px;
					margin-bottom: 10px;
				}
			}

			.news_wrap_skeleton {
				width: 100%;

				.ant-skeleton-image {
					width: 100%;
					height: 190px;
					margin-bottom: 10px;
				}

				.ant-skeleton {
					width: 100%;
					.ant-skeleton-content {
						.ant-skeleton-title {
							margin-bottom: 10px;
						}
						.ant-skeleton-paragraph {
							li:first-child {
								margin-bottom: 10px;
							}
						}
					}
				}
			}
		}
	}
}
